<template>
  <div class="tags-wrap">
    <el-tag
        :key="tag.id"
        v-for="tag in tagList"
        closable
        v-dragging="{ item: tag, list: tagList, group: 'tag' }"
        :disable-transitions="false"
        @close="handleClose(tag)">
      {{ tag.name }}
    </el-tag>
    <el-input
        class="input-new-tag"
        v-if="inputVisible"
        v-model="inputValue"
        ref="saveTagInput"
        size="small"
        @keyup.enter.native="handleInputConfirm"
        @blur="handleInputConfirm">
    </el-input>
    <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 新增收入项</el-button>
  </div>
</template>
<script>
import { debounce } from '@/utils/frequency';
export default {
  name: 'dragTags',
  props: {
    tagList: Array,
    type: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      tag: {},
      inputValue: '',
      inputVisible: false,
    }
  },
  mounted() {
    this.$dragging.$on('dragged', ({ value }) => {
      debounce(() => {
        const reqList = value.list.map((i, idx) => ({ id: i.id, sortBy: idx }));
        this.$emit('handleSort', reqList)
      }, 2000)
    })
  },
  methods: {
    // 显示输入框
    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    // 确定新增收入项
    handleInputConfirm() {
      if (this.inputValue.trim()) {
        this.$emit('handleAdd', this.inputValue, this.type)
      }
      this.inputVisible = false;
      this.inputValue = '';
    },
    // 移除标签
    handleClose(tag) {
      this.$emit('handleDel', tag)
    },
  }
}
</script>
<style lang="scss" scoped>
.tags-wrap {
  .el-tag {
    font-size: 14px; background-color: #FFFFFF;
    height: 54px; line-height: 54px; padding: 0 47px;
    color: #000000; border: 1px solid #e0e0e0;
    position: relative; margin-top: 10px;
    cursor: move;
    &:before {
      content: ''; width: 6px; height: 35px;
      position: absolute; background: url("../../assets/spot.png") no-repeat;
      top: 10px; left: 15px;
    }
  }
  ::v-deep .input-new-tag {
    width: 172px;
    .el-input__inner{ height: 54px }
  }
  .button-new-tag {
    font-size: 14px; height: 54px; line-height: 54px;
    padding: 0 48px;
  }
}
</style>
