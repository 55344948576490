<template>
  <div class="cont">
    <Breadcrumb :crumbs="crumbs" />
    <el-row class="content-box">
      <el-alert title="可拖动排序" show-icon type="warning"></el-alert>
      <el-tabs v-model="activeName">
        <el-tab-pane label="收入项" name="INCOME"></el-tab-pane>
        <el-tab-pane label="支出项" name="EXPEND"></el-tab-pane>
      </el-tabs>
      <div class="multiple-box" v-if="activeName === 'INCOME'">
        <drag-tags @handleAdd="handleAdd" @handleDel="handleDel" @handleSort="handleSort" :tagList="incomeList" type="INCOME" />
      </div>
      <div class="multiple-box" v-else>
        <drag-tags @handleAdd="handleAdd" @handleDel="handleDel" @handleSort="handleSort" :tagList="expendList" type="EXPEND" />
      </div>
    </el-row>
  </div>
</template>
<script>
  import dragTags from '@/components/local/dragTags'
  import { getList, addItem, delItem, sortItem } from '@/api/pms/accommodationSetting/makeANoteSetting'
  import { mapState } from 'vuex'
  export default {
    data() {
      return {
        crumbs: new Map([['PMS'], ['财务设置'], ['记一笔设置']]),
        activeName: 'INCOME',
        incomeList: [],
        incomeTag: '',
        inputValue: '',
        expendList: [],
        itemType: '',
      }
    },
    computed: {
      ...mapState(['hotelInfo']),
    },
    components: {
      dragTags,
    },
    mounted() {
      this.getIncomeAndExpend()
    },
    methods: {
      // 查询收入支出项
      getIncomeAndExpend() {
        const params = {
          hotelId: this.hotelInfo.id,
          companyId: this.hotelInfo.storeId,
        }
        getList(params).then(({ success, records }) => {
          if (!success) return
          this.incomeList.length = this.expendList.length = 0
          Array.isArray(records) &&
            records.forEach((item) => {
              if (item.itemType === 'INCOME') this.incomeList.push(item)
              else if (item.itemType === 'EXPEND') this.expendList.push(item)
            })
        })
      },
      // 新增收入支出项
      handleAdd(name, itemType) {
        const params = {
          hotelId: this.hotelInfo.id,
          companyId: this.hotelInfo.storeId,
          itemType,
          name,
        }
        addItem(params).then(({ success }) => {
          if (success) this.getIncomeAndExpend()
        })
      },
      // 删除收入支出项
      handleDel({ id }) {
        this.$confirm('确定删除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          delItem(`/${id}`).then(({ success }) => {
            if (success) {
              this.$message({ message: '删除成功！', type: 'error' })
              this.getIncomeAndExpend()
            }
          })
        })
      },
      // 排序
      handleSort(reqList) {
        sortItem({ reqList })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .cont {
    .el-alert--warning.is-light {
      padding: 10px;
      border: 1px solid #fa0;
      margin-bottom: 20px;
    }
  }
</style>
